import { graphql } from 'gatsby';
import BlogPostsPagination from '../templates/blog-posts-pagination';

export default BlogPostsPagination;

export const blogPostsQuery = graphql`
  query blogPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(format: HTML, pruneLength: 500)
          fields {
            slug
            teaser
          }
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            authors {
              name
              link
            }
            categories
          }
        }
      }
    }
  }
`;
